import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import type { Observable } from 'rxjs'
import { filter, finalize, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'

import {
  paymentServiceLoadBalance,
  paymentServiceLoading,
  paymentServiceLoadSummary,
  paymentServiceSetBalance,
  paymentServiceSetSummary,
} from '@modules/payment-services/actions/payment-service.actions'
import { selectPaymentServiceBiller } from '@modules/payment-services/selectors/payment-services.selectors'
import { PaymentServicesResource } from '@modules/payment-services/resources/payment-services.resource'
import { IPaymentServiceBalance, IPaymentServiceState } from '@modules/payment-services/interfaces/payment-services'
import { selectCheckoutCard } from '@modules/checkout/state/checkout.selectors'

@Injectable()
export class PaymentServicesEffects {
  /**
   * Load balance available for gift card
   * @type {Observable<any> & CreateEffectMetadata}
   */
  loadPaymentServiceBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentServiceLoadBalance),
      tap(() => {
        this._store.dispatch(paymentServiceLoading({ loading: true }))
      }),
      withLatestFrom(this._store.select(selectPaymentServiceBiller)),
      switchMap(([action, biller]): Observable<any> => {
        return this._paymentServiceResource.balance({}).pipe(
          map((balance: IPaymentServiceBalance) => {
            return paymentServiceSetBalance({
              balance: balance,
            })
          }),
          finalize(() => {
            this._store.dispatch(paymentServiceLoading({ loading: false }))
          })
        )
      })
    )
  )

  /**
   * Load balance available for gift card
   * @type {Observable<any> & CreateEffectMetadata}
   */
  loadPaymentServiceSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentServiceLoadSummary),
      tap(() => {
        this._store.dispatch(paymentServiceLoading({ loading: true }))
      }),
      withLatestFrom(this._store.select(selectPaymentServiceBiller), this._store.select(selectCheckoutCard)),
      filter(([action, biller, card]) => biller !== null),
      switchMap(
        ([action, biller, card]): Observable<any> =>
          this._paymentServiceResource
            .summary({
              biller_id: biller?.arcus_id,
              amount: biller?.amount as number,
            })
            .pipe(
              map(summary => {
                return paymentServiceSetSummary({
                  summary: summary.data,
                  card: card,
                })
              }),
              finalize(() => {
                this._store.dispatch(paymentServiceLoading({ loading: false }))
              })
            )
      )
    )
  )
  constructor(
    private actions$: Actions,
    private _paymentServiceResource: PaymentServicesResource,
    private _store: Store<{ paymentService: IPaymentServiceState }>
  ) {}
}
