import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { getRemoteConfig } from '@angular/fire/remote-config'
import type { RouterEvent } from '@angular/router'
import { NavigationEnd, NavigationStart, Router } from '@angular/router'
import { environment } from '@environments/environment'
/* import { FirebaseConfig } from '@ionic-native/firebase-config/ngx' */
import { Store } from '@ngrx/store'
import { StorageService } from '@services/storage.service'
import { interval } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, skip, take, throttleTime } from 'rxjs/operators'
import * as CONFIG from '../../../../config.json'
import { defaults, put } from '../actions/config.actions'
import { ConfigPipe } from '../pipes/config.pipe'
import type { IConfig } from './../interfaces/config'
import { userProfileSelector } from '@modules/user/selectors/user.selectors'
import { RemoteConfig } from '@firebase/remote-config'

@Injectable({ providedIn: 'root' })
export class ConfigService {
  protected remoteConfig: RemoteConfig | any
  protected config!: IConfig
  protected config$
  protected profile$ = this.store.select(userProfileSelector)

  constructor(
    protected router: Router,
    protected storage: StorageService,
    protected store: Store<{ config: IConfig }>,
    protected httpClient: HttpClient,
    protected configPipe: ConfigPipe /* protected firebaseConfig: FirebaseConfig */
  ) {
    this.config$ = this.store.select('config')
  }

  /**
   * get key form config
   *
   * @param key
   */
  get(key: string): any {
    return this.configPipe.transform(this.config, key)
  }

  /**
   * Init
   */
  async init(): Promise<void> {
    this.remoteConfig = getRemoteConfig()
    this.remoteConfig.defaultConfig = CONFIG
    this.remoteConfig.settings.minimumFetchIntervalMillis = 30000

    this.config$
      .pipe(
        distinctUntilChanged(),
        filter(response => !!Object.keys(response)?.length)
      )
      .subscribe((response: IConfig) => {
        if (response) {
          this.config = response
          const intervalRecovery = this.get('FLAG_INTERVAL_RECOVERY_CONFIG')
          // validate for performance
          if (intervalRecovery > 10000) {
            this.remoteConfig.settings.minimumFetchIntervalMillis = intervalRecovery
          }
        }
      })

    // watch navigation for recoveri config
    this.router.events
      .pipe(
        skip(1),
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        distinctUntilChanged(),
        throttleTime(environment.production ? 60 * 1000 : 6000 * 1000)
      )
      .subscribe(() => {
        this.fetch()
      })

    // recovery data if de conection is error and  storaage
    const config = await this.storage.get('CONFIG')
    if (config) {
      this.store.dispatch(
        defaults({
          payload: config,
        })
      )
    }

    if (!environment.develop) {
      this.initInterval()
    }
  }

  protected fetch(): void {
    //no content
  }
  protected parse(config: any): any {
    //no content
  }

  /**
   * Interval recovery data
   */
  protected initInterval(): void {
    interval(this.remoteConfig.settings.minimumFetchIntervalMillis)
      .pipe(debounceTime(5000))
      .subscribe(() => {
        this.fetch()
      })
  }

  /**
   * Recovery config default
   */
  protected defaultRemote(): void {
    this.httpClient
      .get(`${environment.wepApp}/config.json`, {
        withCredentials: false,
        params: {
          skipInterceptor: true,
        },
      })
      .subscribe(
        response => {
          this.setConfig(response as IConfig)
        },
        () => {
          //catch error no load config remote alternative
        }
      )
  }

  /**
   * Set config to state and set storage
   *
   * @param config
   */
  protected setConfig(config: IConfig): void {
    this.profile$.pipe(take(1)).subscribe(profile => {
      for (const key in config) {
        if (Object.prototype.hasOwnProperty.call(config, key)) {
          if (!key.startsWith('FLAG_')) {
            continue
          }
          //check if exist flag for user
          const keyConfig = `${key}_USERS` as keyof IConfig
          if (config[keyConfig] && config[keyConfig]?.length > 0 && profile) {
            if (config[keyConfig].includes(profile.id)) {
              console.debug(`DEFINE ${key} TO TRUE`)
              config = {
                ...config,
                [key]: true,
              }
            }
          }
        }
      }
      // dispatch state
      this.store.dispatch(
        put({
          payload: config,
        })
      )
      // update storage
      this.storage.set('CONFIG', config)
    })
  }
}
