import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'distance',
  standalone: true,
})
export class DistancePipe implements PipeTransform {
  transform(meters: number): string {
    if (meters < 1000) {
      return `A ${meters} Metros`
    }
    const kilometers = meters / 1000
    return `A ${kilometers.toFixed(2)} Kilómetros`
  }
}
