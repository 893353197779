import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BenefitCardComponent } from './benefit-card.component'
import { IonicModule } from '@ionic/angular'
import { RouterModule } from '@angular/router'
import { SharedPipesModule } from '@pipes/shared-pipes.module'

@NgModule({
  imports: [CommonModule, IonicModule, RouterModule, SharedPipesModule],
  declarations: [BenefitCardComponent],
  exports: [BenefitCardComponent],
  providers: [],
})
export class BenefitCardModule {}
