import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { IonContent, IonicModule, ModalController, NavController } from '@ionic/angular'
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common'
import { ToolbarModule } from '@components/toolbar/toolbar.module'
import { PlatformService } from '@services/platform.service'
import { Capacitor } from '@capacitor/core'
import { IUserState, UserStatus } from '@modules/user/interfaces/user'
import { Store } from '@ngrx/store'
import { Observable, take } from 'rxjs'
import { selectUser } from '@modules/user/selectors/user.selectors'
import { IconModule } from '@components/atoms/icon/icon.module'
import { Router } from '@angular/router'
import { UserModel } from '@modules/user/models/user.model'
import { PushPipe } from '@ngrx/component'
import { addIcons } from 'ionicons'
import { close, personOutline, chevronForwardOutline } from 'ionicons/icons'

@Component({
  selector: 'app-content-main',
  templateUrl: './content-main.component.html',
  styleUrls: ['./content-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, NgIf, ToolbarModule, NgTemplateOutlet, NgClass, PushPipe, IconModule],
})
export class ContentMainComponent implements OnInit {
  @ViewChild(IonContent) content!: IonContent
  @Input() templateHeader!: TemplateRef<any>
  @Input() templateExtraButtons!: TemplateRef<any>
  @Input() menu = true
  @Input() menuFixed = false
  @Input() isModal = false
  @Input() colorToolbar: 'primary' | 'light' | 'dark' | 'transparent' | 'dark-gradient' = 'light'
  @Input() showToolbar = true
  @Input() partialBakground: 'primary' | 'light' | 'dark' | 'dark-gradient' | null = null
  @Input() isClosable = true
  @Input() disableClose = false
  @Input() padding = true
  @Input() loadIndicatorNewNotification = true
  @Input() background = false
  @Input() completeProfileBanner = false
  @Output() close = new EventEmitter()
  isNative = Capacitor.isNativePlatform()

  user$!: Observable<UserModel | undefined>
  UserStatus = UserStatus

  @HostBinding('class.partial-background-primary') get isPartialBackgroundPrimary(): boolean {
    return this.partialBakground === 'primary'
  }

  @HostBinding('class.partial-background-dark') get isPartialBackgroundDark(): boolean {
    return this.partialBakground === 'dark'
  }

  @HostBinding('class.partial-background-dark-gradient') get isPartialBackgroundDarkGradient(): boolean {
    return this.partialBakground === 'dark-gradient'
  }

  @HostBinding('class.without-toolbar') get withoutToolbar(): boolean {
    return !this.showToolbar
  }

  @HostBinding('class.ios') get osIos(): boolean {
    return Capacitor.getPlatform() === 'ios'
  }

  constructor(
    protected _modalCtrl: ModalController,
    protected _navCtrl: NavController,
    public el: ElementRef,
    protected _platformService: PlatformService,
    protected _router: Router,
    private _store: Store<{ user: IUserState }>
  ) {
    this.user$ = this._store.select(selectUser)
    addIcons({ close, personOutline, chevronForwardOutline })
  }

  onClose(): void {
    if (this.close.observed) {
      this.close.emit()
      return
    }
    if (this.disableClose) return
    if (this.isModal) {
      this._modalCtrl.dismiss()
    } else {
      this._navCtrl.navigateRoot('/u/home', { replaceUrl: true })
    }
  }

  scrollToTop() {
    this.content.scrollToTop(0)
  }

  ngOnInit() {
    if (this._platformService.isSm()) {
      this.menuFixed = true
    }
  }

  onLocateCompany(): void {
    this.user$.pipe(take(1)).subscribe((user?: UserModel) => {
      if (!user?.hasCompany) {
        this._router.navigate(['/u/locate-your-company'])
      }
    })
  }

  ionViewDidEnter() {
    this.scrollToTop()
  }
}
