import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { NgModule } from '@angular/core'
import IconComponent from './icon.component'
import { CommonModule } from '@angular/common'
import IconService from '@atoms/icon/icon.service'
import { IonicModule } from '@ionic/angular'

@NgModule({ declarations: [IconComponent],
    exports: [IconComponent], imports: [CommonModule, IonicModule], providers: [IconService, provideHttpClient(withInterceptorsFromDi())] })
export class IconModule {}
