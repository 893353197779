<ng-container *ngIf="profile">
  <div class="flex items-center mt-[20px] md:mt-0" [ngClass]="{ 'text-white': color !== 'light' }">
    <div class="text-center contents">
      <ion-avatar class="min-w-25 w-25 h-25 text-center" *ngIf="!viewState">
        <img
          class="object-cover min-w-25 w-25 h-25"
          *ngIf="profile.pictureUrl; else imagePlaceholder"
          [src]="profile.pictureUrl"
        />
      </ion-avatar>
      <ion-avatar
        class="min-w-25 w-25 h-25 bg-black text-center h-ful z-[2]"
        [ngClass]="{ 'not-empty bg-black': picture }"
        *ngIf="viewState"
      >
        <ng-container *ngIf="!viewState.isInEditMode; else editImageTemplate">
          <img
            class="object-cover min-w-25 w-25 h-25"
            *ngIf="profile.pictureUrl; else imagePlaceholder"
            [src]="profile.pictureUrl || picture"
          />
        </ng-container>
      </ion-avatar>
    </div>
    <div class="ml-1 w-100">
      <h3
        data-test-id="full-name"
        class="text-[22px] my-0 font-semibold leading-[24px] tracking-widest"
        [ngClass]="url === '/u/user-profile' ? 'text-dark' : 'light'"
      >
        {{ profile.fullName }}
      </h3>
      <div
        data-test-id="monto-id"
        class="text-lg tracking-widest font-semibold"
        *ngIf="profile.montoId"
        [ngClass]="url === '/u/user-profile' ? 'text-primary' : 'light'"
      >
        <btn-complete-profile
          class="block md:hidden"
          *ngIf="!(user$ | ngrxPush)?.hasCompany && !viewState"
        ></btn-complete-profile>
        <ng-container *ngIf="(user$ | ngrxPush)?.hasCompany">
          <span class="font-semibold truncate text-base block max-w-[227px]">{{ profile.companyName }}</span>
        </ng-container>
      </div>
      <div class="pr-[6px]">
        <h5
          data-test-id="monto-cash-text"
          class="my-0 font-semibold my-[5px] text-base leading-[22px] md:text-lg tracking-widest"
        >
          Monto Cash:
        </h5>
        <span class="flex" [ngClass]="url === '/u/user-profile' ? 'text-primary' : 'light'">
          <div data-test-id="monto-cash-available" class="my-0 font-semibold flex place-items-center">
            <img class="-mt-[4px] mr-1 md:mr-0 -ml-[3px]" src="../../../../../assets/icon/ic-monto-cash.svg" />
            <span class="min-w-[58px] mr-1 leading-[18px] tracking-widest">
              {{ montoCashbalance$ | ngrxPush | currency: '':'symbol-narrow':'1.0-0' }}
            </span>
          </div>
          <ion-button
            class="rounded-3 w-[63px] h-4 p-0 m-0"
            size="small"
            [disabled]="!((montoCashbalance$ | ngrxPush) > 0) || (montoCashIsLoading$ | ngrxPush)"
            [routerLink]="['/u/monto-cash']"
            [color]="color === 'light' ? 'primary' : 'light'"
            >Usar</ion-button
          >
        </span>
      </div>
    </div>
  </div>
  <ng-template #imagePlaceholder>
    <ng-container *ngIf="user$ | ngrxPush as user">
      <div class="h-full w-full text-white flex justify-center items-center rounded-full bg-dark">
        <div class="text-white font-semibold flex items-center mx-auto text-[50px] uppercase">
          {{ user.firstName?.charAt(0) }}{{ user.lastName?.charAt(0) }}
        </div>
      </div>
    </ng-container>
  </ng-template>
  <ng-template #editImageTemplate>
    <ion-avatar class="min-w-25 w-25 h-25 bg-black text-center h-ful z-[2]">
      <div [ngClass]="{ 'not-empty': picture }" tappable>
        <div class="edit-image-container w-24 h-24 mx-auto mb-[6px]" (click)="fileInput.click()">
          <input
            #fileInput
            id="file-input"
            type="file"
            accept="image/*"
            name="name"
            (change)="setFile.emit(fileInput.files)"
          />
          <img
            class="object-cover min-w-25 w-25 h-25 bg-white rounded-[50px]"
            [ngClass]="{
              'opacity-[0.19]': 'assets/icon/ic_person_outline_blue.svg',
              'opacity-[0.55]': picture || profile.pictureUrl
            }"
            [src]="picture || profile.pictureUrl || 'assets/icon/ic_person_outline_blue.svg'"
          />
          <img class="absolute mt-6 h-[19.5px] opacity-100" [src]="'assets/icon/ic_camera_white.svg'" />
        </div>
      </div>
    </ion-avatar>
  </ng-template>
</ng-container>
