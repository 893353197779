import { EventEmitter, OnInit, Output } from '@angular/core'
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  TemplateRef,
  OnChanges,
  AfterViewInit,
  OnDestroy,
} from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { environment } from '@environments/environment'
import { MenuController, ModalController, NavController } from '@ionic/angular'
import type { INotificationState } from '@modules/notification/interfaces/notification'
import { indicatorNewNotification } from '@modules/notification/selectors/notifications.selectors'
import { NotificationService } from '@modules/notification/services/notification.service'
import { Store } from '@ngrx/store'
import { FcmService } from '@modules/notification/services/fcm.service'
import type { Observable } from 'rxjs'
import { filter, Subject } from 'rxjs'
import { ContainerService } from 'src/app/containers/container.service'
import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar'
import { takeUntil } from 'rxjs/operators'
import { ISiteTreeHeader, ISiteTreeState } from '@modules/app/interfaces/site-tree'
import { selectSiteTreeHeader } from '@modules/app/selectors/site-tree.selectors'
import { addIcons } from 'ionicons'
import { menu } from 'ionicons/icons'

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent extends ContainerService implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() templateHeader!: TemplateRef<any>
  @Input() loadIndicatorNewNotification = true
  @Input() templateExtraButtons!: TemplateRef<any>
  @Input() disableClose = false

  siteTreeHeader$: Observable<ISiteTreeHeader | undefined>

  environment = environment

  @Input()
  color: 'light' | 'primary' | 'dark-gradient' | 'dark' | 'transparent' | null = 'light'

  @Input()
  isModal = false

  @Input()
  menu = false

  @Input()
  notifications = true

  indicatorNewNotification$: Observable<boolean>

  url: string

  @Input() closable = true
  @Output() close = new EventEmitter()

  protected _unsubscribeAll = new Subject()

  constructor(
    protected _modalCtrl: ModalController,
    protected _navCtrl: NavController,
    protected _menuCtrl: MenuController,
    protected override fcmService: FcmService,
    private _notificationService: NotificationService,
    private _store: Store<{ notification: INotificationState; siteTree: ISiteTreeState }>,
    private _cdr: ChangeDetectorRef,
    protected override _router: Router,
    private elementRef: ElementRef
  ) {
    super()
    this.siteTreeHeader$ = this._store.select(selectSiteTreeHeader)
    this.indicatorNewNotification$ = this._store.select(indicatorNewNotification)
    this.url = this._router.url

    this._router.events
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((event): event is NavigationEnd => event instanceof NavigationEnd)
      )
      .subscribe(response => {
        if (this.url === response.url) {
          this.setStatusBarColor(this.color)
        }
      })
    addIcons({menu})
  }

  ngOnChanges(): void {
    if (this.menu) {
      this.color = 'primary'
    }
    if (this.isModal) {
      this.loadIndicatorNewNotification = false
    }
  }

  override async ngOnInit(): Promise<void> {
    // check indicator notifications new
    this.isModal = !!this.elementRef?.nativeElement?.closest('ion-modal')
    this._cdr.detectChanges()
    if (this.loadIndicatorNewNotification) {
      this._notificationService.getIndicatorNew()
    }
  }

  onClose(): void {
    if (this.close.observed) {
      this.close.emit()
      return
    }
    this._cdr.detectChanges()
    if (this.isModal) {
      this._modalCtrl.dismiss()
    } else {
      this._navCtrl.navigateRoot('/u/home', { replaceUrl: true })
    }
  }

  openNotifications(): void {
    this.fcmService.pushReceived = false
    this._notificationService.openNotifications()
  }

  openMenu(): void {
    this._menuCtrl.open()
  }

  ngAfterViewInit(): void {
    this.setStatusBarColor(this.color)
  }

  setStatusBarColor(color: string | null) {
    if (!Capacitor.isNativePlatform()) {
      return
    }
    if (color === 'light') {
      StatusBar.setStyle({ style: Style.Light })
      if (Capacitor.getPlatform() === 'android') {
        StatusBar.setBackgroundColor({ color: '#ffffff' })
      }
    } else {
      StatusBar.setStyle({ style: Style.Dark })
      if (Capacitor.getPlatform() === 'android') {
        StatusBar.setBackgroundColor({ color: '#102A51' })
      }
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true)
    this._unsubscribeAll.complete()
  }
}
