import { Injectable } from '@angular/core'
import { IResourceMethodObservable, ResourceAction, ResourceParams, ResourceRequestMethod } from '@ngx-resource/core'
import { ResourceBaseService } from '@services/resource.base.service'
import { Observable } from 'rxjs'
import { environment } from '@environments/environment'
import { IGiftCardSummary } from '@modules/gift-card/interfaces/gift-card'

@Injectable()
@ResourceParams({
  pathPrefix: 'bill_payments',
})
export class PaymentServicesResource extends ResourceBaseService {
  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/billers_by_type',
  })
  billersByType: IResourceMethodObservable<any, any> | any

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/biller_by_group',
  })
  billerByGroup: IResourceMethodObservable<any, any> | Observable<any> | any

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    url: environment.API + '/api/v3/',
    pathPrefix: '/bill_payments',
    path: '/biller_by_group',
  })
  billerGroup: IResourceMethodObservable<any, any> | Observable<any> | any

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/single/pay',
  })
  singlePay: IResourceMethodObservable<any, any> | any

  @ResourceAction({
    url: environment.API + '/api/v3/',
    method: ResourceRequestMethod.Post,
    path: '/card_pay',
  })
  payWithCard: IResourceMethodObservable<any, any>

  @ResourceAction({
    url: environment.API + '/api/v3/',
    pathPrefix: '/bill_payments',
    method: ResourceRequestMethod.Post,
    path: '/favorite',
  })
  like!: IResourceMethodObservable<
    {
      service: string
    },
    any
  >

  @ResourceAction({
    url: environment.API + '/api/v3/',
    pathPrefix: '/bill_payments',
    method: ResourceRequestMethod.Delete,
    path: '/favorite',
  })
  unlike!: IResourceMethodObservable<
    {
      service: string
    },
    any
  >

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/search/billers',
  })
  searchBillers: IResourceMethodObservable<any, any> | Observable<any> | any

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/balance',
  })
  balance: IResourceMethodObservable<any, any> | Observable<any> | any

  @ResourceAction({
    url: environment.API + '/api/v3/',
    method: ResourceRequestMethod.Post,
    path: '/summary',
  })
  summary!: IResourceMethodObservable<
    {
      biller_id: string
      amount: number
    },
    {
      data: IGiftCardSummary
    }
  >

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/favorites',
  })
  getFavorites: IResourceMethodObservable<any, any> | Observable<any> | any

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/favorites',
  })
  setFavorite: IResourceMethodObservable<any, any> | Observable<any> | any

  @ResourceAction({
    method: ResourceRequestMethod.Delete,
    path: '/favorites',
  })
  deleteFavorite: IResourceMethodObservable<any, any> | Observable<any> | any

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/billers?category=1',
  })
  billerTopUps: IResourceMethodObservable<any, any> | Observable<any> | any
}
