import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { withdrawalsClear } from '@modules/withdrawal/actions/withdrawal.actions'
import { checkoutPaymentSuccess } from '@modules/checkout/state/checkout.actions'
import { Store } from '@ngrx/store'
import { recordsClearAll } from '@modules/record/actions/record.actions'
import posthog from 'posthog-js'
import { selectCheckoutPaymentMethod } from '@modules/checkout/state/checkout.selectors'
import { map, withLatestFrom } from 'rxjs/operators'
import { ETypePaymentMethod } from '@modules/checkout/checkout'
import { montoCashLoadBalance } from '@modules/monto-cash/actions/monto-cash.actions'

@Injectable()
export class CheckoutEffects {
  checkoutPaymentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(checkoutPaymentSuccess),
        withLatestFrom(this._store.select(selectCheckoutPaymentMethod)),
        map(([action, paymentMethod]) => {
          if (paymentMethod) {
            posthog.capture(
              `Transaction Success - Payment Method - ${paymentMethod.type === ETypePaymentMethod.SALARY ? paymentMethod.id : paymentMethod.type}`,
              {
                type: paymentMethod.type,
                salary_future: paymentMethod.salary_future,
              }
            )
            if (paymentMethod.type === ETypePaymentMethod.SALARY) {
              this._store.dispatch(montoCashLoadBalance())
            }
          }
          this._store.dispatch(withdrawalsClear())
          this._store.dispatch(recordsClearAll())
        })
      ),
    { dispatch: false }
  )

  constructor(
    private actions$: Actions,
    private _store: Store
  ) {}
}
