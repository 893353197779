import { Injectable } from '@angular/core'
import { IResourceMethodObservable, ResourceAction, ResourceParams, ResourceRequestMethod } from '@ngx-resource/core'
import { ResourceBaseService } from '@services/resource.base.service'
import { IStore } from '@components/pages/stores/stores'
import { environment } from '@environments/environment'

@Injectable()
@ResourceParams({
  pathPrefix: '/catalogs/stores',
  url: environment.API + '/api/v3/',
})
export class StoresResource extends ResourceBaseService {
  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/',
  })
  override list!: IResourceMethodObservable<
    {
      provider: string
      lat: number | null
      lng: number | null
      limit: number
      offset: number
    },
    {
      data: IStore[]
      count: number
    }
  >
}
