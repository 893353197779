import { Injectable } from '@angular/core'
import { Filesystem } from '@capacitor/filesystem'
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx'
import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { ModalController } from '@ionic/angular'
import { PermissionModalComponent } from '@components/molecules/permission-modal/permission-modal.component'

@Injectable({
  providedIn: 'root',
})
export class PermissionsDocsService {
  constructor(private _openNativeSettings: OpenNativeSettings, private _modal: ModalController) {}

  async openAppDetails() {
    const modal = await this._modal.create({
      component: PermissionModalComponent,
      cssClass: 'auto-height app-permission-modal',
    })

    await modal.present()

    const { data } = await modal.onWillDismiss()
    if (data?.value) {
      this._openNativeSettings.open('application_details')
    }
  }

  /**
   * Requests permissions of different platforms and call upload callback
   * @param uploadCallback
   */
  requestPermissions(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const platform = Capacitor.getPlatform()
      if (Capacitor.getPlatform() === 'web' || platform === 'ios') {
        resolve()
        return
      }

      resolve()
    })
  }
}
