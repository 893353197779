import { Injectable } from '@angular/core'
import { IDeleteUser } from './../interfaces/user'
import { IResourceMethodObservable, ResourceAction, ResourceParams, ResourceRequestMethod } from '@ngx-resource/core'
import { ResourceBaseService } from '@services/resource.base.service'
import { environment } from '@environments/environment'
import { IAccountInfoResponse } from '@modules/user/interfaces/account-info-response'
import { IUserResponse } from '@modules/user/interfaces/user-response'
import { IProfileResponse } from '@modules/user/interfaces/profile-response'
import type { UserToken } from '@models/user-token'
import { IReferConfig } from '@modules/refer/interfaces/refer'

@Injectable()
@ResourceParams({
  pathPrefix: 'users',
})
export class UserResource extends ResourceBaseService {
  @ResourceAction({
    url: environment.API + '/api/v3/',
    pathPrefix: '/sessions/challenge',
    method: ResourceRequestMethod.Post,
  })
  checkMFA!: IResourceMethodObservable<
    {
      session: string
      challenge_name: string
      email: string
      mfa_code: string
    },
    UserToken
  >

  @ResourceAction({
    url: environment.API + '/api/v3/',
    pathPrefix: '/referral_settings',
    method: ResourceRequestMethod.Get,
  })
  referralSettings!: IResourceMethodObservable<null, IReferConfig>

  @ResourceAction({
    url: environment.API + '/api/v3/',
    pathPrefix: 'sessions/associate_software',
    method: ResourceRequestMethod.Get,
  })
  associateSoftware!: IResourceMethodObservable<
    null,
    {
      secret_code: string
      url: string
    }
  >

  @ResourceAction({
    url: environment.API + '/api/v3/',
    pathPrefix: 'sessions/disable_mfa',
    method: ResourceRequestMethod.Post,
  })
  disableMFA!: IResourceMethodObservable<null, null>

  @ResourceAction({
    url: environment.API + '/api/v3/',
    pathPrefix: 'sessions/verify_software',
    method: ResourceRequestMethod.Post,
  })
  verifySoftware!: IResourceMethodObservable<
    {
      user_code: string
    },
    {
      status: string
    }
  >

  @ResourceAction({
    method: ResourceRequestMethod.Delete,
    path: '',
  })
  delete!: IResourceMethodObservable<IDeleteUser, any>

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '',
  })
  get!: IResourceMethodObservable<null, IUserResponse>

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    url: environment.API,
    path: '',
    pathPrefix: '/api/v3/profile',
  })
  profile!: IResourceMethodObservable<null, IProfileResponse>

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    url: environment.API,
    path: '',
    pathPrefix: '/account_info',
  })
  account!: IResourceMethodObservable<null, IAccountInfoResponse>

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    url: environment.API,
    pathPrefix: '/api/v3/profile',
    path: '/update_tutorials',
  })
  tutorials!: IResourceMethodObservable<any, any>
}
