import { NgModule } from '@angular/core'
import { NotificationListModule } from './components/notification-list/notification-list.module'
import { NotificationModalFullscreenModule } from './components/notification-modal-fullscreen/ notification-modal-fullscreen.module'
import { NotificationModalModule } from './components/notification-modal/notification-modal.module'
import { NotificationService } from './services/notification.service'

@NgModule({
  imports: [NotificationListModule, NotificationModalModule, NotificationModalFullscreenModule],
  exports: [NotificationListModule, NotificationModalModule, NotificationModalFullscreenModule],
  providers: [NotificationService],
})
export class NotificationModule {}
