import { createFeatureSelector, createSelector } from '@ngrx/store'
import { ISiteTreeState } from '@modules/app/interfaces/site-tree'

export const siteTreeFeatureSelector = createFeatureSelector<ISiteTreeState>('siteTree')

export const selectSiteTree = createSelector(siteTreeFeatureSelector, state => state?.siteTree)

export const selectSiteTreeMenu = createSelector(siteTreeFeatureSelector, state => state?.siteTree?.menu)
export const selectSiteTreeTabs = createSelector(siteTreeFeatureSelector, state => state?.siteTree?.tabs)
export const selectSiteTreeHeader = createSelector(siteTreeFeatureSelector, state => state?.siteTree?.header)
export const selectSiteTreeHome = createSelector(siteTreeFeatureSelector, state => state?.siteTree?.home)

export const selectSiteTreeLoading = createSelector(siteTreeFeatureSelector, state => state?.loading)
