<label *ngIf="label" [for]="id" [class]="customClass">
  <div class="input-title pb-[4px]">
    <span class="input-name">
      {{ label }}
      <span *ngIf="required" class="text-danger">*</span>
    </span>
  </div>
</label>
<ion-input
  [attr.maxlength]="maxlength"
  [attr.name]="name ? name : id"
  [type]="type"
  [attr.inputmode]="inputmode"
  [attr.readonly]="readonly"
  [attr.disabled]="disabled"
  [autocomplete]="autocomplete"
  [value]="value"
  (input)="changed($event)"
  [id]="id"
  [placeholder]="placeholder"
  #inputref
>
</ion-input>
