<ng-template #headerTpl>
  <h2 *ngIf="title" [innerHTML]="title | sanitizeHtml: 'html'"></h2>
</ng-template>
<app-content-main [templateHeader]="headerTpl" [menu]="false">
  <div class="text-dark">
    <div class="text-dark pb-[30px]" *ngIf="legend" [innerHTML]="legend | sanitizeHtml: 'html'"></div>
    <div
      class="grid grid-flow-row-dense grid-cols-1 gap-3.5 md:gap-6 col-auto w-full justify-center place-content-center mx-auto max-w-web-area"
    >
      <ng-container *ngIf="isLoading">
        <ng-container *ngFor="let card of [1, 2, 3, 4, 5, 6, 7, 8, 9]">
          <ion-card class="w-full sm:max-w-full min-h-[120px] mx-auto bg-[#F7F7FC]">
            <ion-skeleton-text class="p-0 m-0 h-full" animated></ion-skeleton-text>
          </ion-card>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let entity of stores">
        <ion-card class="w-full sm:max-w-full min-h-[120px] mx-auto bg-[#F7F7FC]" tappable>
          <ion-card-content
            class="px-3.5 flex py-3.5 gap-[30px] h-full tracking-normal bg-[#F7F7FC]"
            [ngClass]="{ 'justify-around items-center': showLogo }"
          >
            <div class="h-[40px] w-1/3 md:1/2 text-center" *ngIf="showLogo && entity?.provider">
              <ion-img [src]="entity?.provider?.logo" class="object-scale-down h-full"></ion-img>
            </div>
            <div class="flex flex-col leading-4 legend" [ngClass]="{ 'w-2/3 md:1/2': showLogo, 'w-full': !showLogo }">
              <div class="flex-grow">
                <div class="text-primary pb-[5px]" *ngIf="entity?.distance">
                  {{ +(entity?.distance || 0).toFixed() | distance }}
                </div>
                <div *ngIf="showName && entity?.name" class="font-semibold pb-[5px]">{{ entity?.name }}</div>
                <div *ngIf="entity?.description" class="pb-[5px]">{{ entity?.description }}</div>
                <div *ngIf="entity?.address" class="pb-[5px]">{{ entity?.address }}</div>
                <div *ngIf="entity?.schedule" [innerHTML]="entity?.schedule | sanitizeHtml: 'html'"></div>

                <a
                  *ngIf="entity?.url"
                  class="underline text-primary font-normal"
                  (click)="onOpenUrl(entity.url)"
                  tappable=""
                  >Ver más</a
                >
              </div>
              <a
                *ngIf="entity.lng"
                (click)="
                  geolocationService.openMapTo({
                    latitude: entity.lat || undefined,
                    longitude: entity.lng,
                    address: entity.address
                  })
                "
                class="font-normal underline pt-[5px]"
                >Ver en mapa</a
              >
            </div>
          </ion-card-content>
        </ion-card>
      </ng-container>
    </div>
    <div class="text-center pt-5" *ngIf="showMore">
      <a href="javascript:" class="text-primary underline text-base" (click)="onLoadMore()">Ver más</a>
    </div>
  </div>
  <p *ngIf="footer" [innerHTML]="footer | sanitizeHtml: 'html'"></p>
  <ion-footer class="pt-3.5">
    <ion-toolbar color="light" class="p-0">
      <ion-button expand="block" color="light" (click)="onDissmiss()"> Regresar </ion-button>
    </ion-toolbar>
  </ion-footer>
</app-content-main>
