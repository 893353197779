import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable, Injector } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '@environments/environment'
import { ModalController } from '@ionic/angular'
import { Store } from '@ngrx/store'
import { PlatformService } from '@services/platform.service'
import { StorageService } from '@services/storage.service'
import { ToastService } from '@services/toast.service'
import type { Observable } from 'rxjs'
import { modalEnterAnimation, modalLeaveAnimation } from 'src/app/shared/animations/ModalAnimations'
import { notificationIndicatorNew, notificationReceived } from '../actions/notification.actions'
import { NotificationListComponent } from '../components/notification-list/notification-list.component'
import { NotificationModalFullscreenComponent } from '../components/notification-modal-fullscreen/notification-modal-fullscreen.component'
import { NotificationModalComponent } from '../components/notification-modal/notification-modal.component'
import { NotificationStatus, NotificationType } from '../enums/notification'
import type { INotification, INotificationState } from '../interfaces/notification'
import { withdrawalsClear } from '@modules/withdrawal/actions/withdrawal.actions'
import { recordsClearAll } from '@modules/record/actions/record.actions'
import { Badge } from '@capawesome/capacitor-badge'

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _headers = new HttpHeaders().set('Accept', 'application/vnd.mto.v2+json').set('X-Client', 'app')

  constructor(
    private httpClient: HttpClient,
    private _storageService: StorageService,
    private _store: Store<{ notification: INotificationState }>,
    private _toastService: ToastService,
    private _router: Router,
    private _modalController: ModalController,
    private _injector: Injector
  ) {
    /** Do nothing */
  }

  getNotifications(params: Record<string, any>): Observable<any> {
    return this.httpClient.get(`${environment.API}/api/v2/notifications`, {
      params,
      headers: this._headers,
    })
  }

  read(id: string): Observable<{ data: INotification }> {
    return this.httpClient.patch<{ data: INotification }>(
      `${environment.API}/api/v2/notifications/${id}/read`,
      {},
      {
        headers: this._headers,
      }
    )
  }

  delete(id: string): Observable<{ data: INotification }> {
    return this.httpClient.delete<{ data: INotification }>(`${environment.API}/api/v2/notifications/${id}`, {
      headers: this._headers,
    })
  }

  getIndicatorNew(): void {
    this.getNotifications({ page: 1 }).subscribe(
      async (response: { meta: { pagination: any; unread: number }; notifications: INotification[] }) => {
        const typesModal: string[] = [
          NotificationType.MODAL,
          NotificationType.FULLSCREEN,
          NotificationType.MODAL_PUSH,
          NotificationType.FULLSCREEN_PUSH,
        ]
        if (response?.meta?.unread !== undefined) {
          Badge.isSupported().then(result => {
            if (result.isSupported) {
              Badge.set({ count: response.meta.unread })
            }
          })
          this._store.dispatch(notificationIndicatorNew({ indicator: response.meta.unread > 0 }))
        }

        //check the last id for display indicator new
        const lastNotificationId = await this._storageService.get('LAST_NOTIFICATIONS_ID')

        if (response?.notifications.length > 0) {
          //chec if new notification for mark
          if (
            response?.notifications.length > 0 &&
            response?.notifications[0].id !== lastNotificationId &&
            response?.notifications[0]?.status === NotificationStatus.UNREAD
          ) {
            if (typesModal.includes(response?.notifications[0]?.notification_type as NotificationType)) {
              this.createNotification(response?.notifications[0])
            }
            if (!lastNotificationId) {
              this._storageService.set('LAST_NOTIFICATIONS_ID', response?.notifications[0].id)
            }
            return
          } else {
            //if not new notification check other peding for view modals
            const modalNotification = response?.notifications.find(notification =>
              typesModal.includes(notification?.notification_type as NotificationType)
            )
            if (modalNotification && modalNotification?.status === NotificationStatus.UNREAD) {
              this.createNotification(modalNotification)
              return
            }
          }
        }
      },
      () => {
        //no found notification
      }
    )
  }

  async createNotificationToast(notification: INotification): Promise<void> {
    if (!notification?.title) {
      return
    }
    const toast = await this._toastService.notification({
      message: notification?.body,
      header: notification?.title,
      duration: 8000,
    })
    document
      .querySelector('ion-toast')
      ?.shadowRoot?.querySelector('.toast-container')
      ?.addEventListener(
        'click',
        () => {
          toast.dismiss()
          this.openNotifications()
        },
        false
      )
    const { role } = await toast.onDidDismiss()
  }

  async openNotifications(): Promise<void> {
    const _platformService = this._injector.get(PlatformService)
    if (_platformService.isSm) {
      const _router = this._injector.get(Router)
      _router.navigate(['/u/notifications'])
      return Promise.resolve()
    }
    const _modalCtrl = this._injector.get(ModalController)
    const modal = await _modalCtrl.create({
      component: NotificationListComponent,
      enterAnimation: modalEnterAnimation,
      leaveAnimation: modalLeaveAnimation,
    })
    return modal.present()
  }

  async createNotificationModal(notification: INotification): Promise<void> {
    let cssClass = 'modal-notification'
    if (
      [NotificationType.FULLSCREEN, NotificationType.FULLSCREEN_PUSH].includes(
        notification?.notification_type as NotificationType
      )
    ) {
      cssClass = 'modal-notification-fullscreen'
    }
    const modal = await this._modalController.create({
      component: [NotificationType.FULLSCREEN, NotificationType.FULLSCREEN_PUSH].includes(
        notification?.notification_type as NotificationType
      )
        ? NotificationModalFullscreenComponent
        : NotificationModalComponent,
      componentProps: {
        notification: notification,
      },
      backdropDismiss: notification?.buttons?.length ? false : true,
      cssClass: `${cssClass}`,
    })
    if ([NotificationType.FULLSCREEN_PUSH, NotificationType.MODAL_PUSH].includes(notification?.notification_type)) {
     this.readNotificaction(notification)
    }
    await modal.present()
    await modal.onWillDismiss()
    this.updateHistory(notification)
    this.readNotificaction(notification)
  }

  readNotificaction(notification: INotification) {
    this.read(notification.id).subscribe((response) => {
      if (response?.data?.unread !== undefined) {
        Badge.isSupported().then(result => {
          if (result.isSupported) {
            Badge.set({ count: response.data.unread || 0 })
          }
        })
        this._store.dispatch(notificationIndicatorNew({ indicator: response.data.unread > 0 }))
      }
    })
  }

  createNotification(notification: any): void {
    //if notification from worker merge with original data
    if (notification?.data) {
      notification = {
        ...notification,
        ...notification?.data,
      }
    }

    if (typeof notification?.buttons === 'string') {
      notification.buttons = JSON.parse(notification?.buttons)
    }
    const notif = { ...notification }
    this._store.dispatch(notificationReceived({ notification: notif }))

    if (
      [
        NotificationType.MODAL,
        NotificationType.FULLSCREEN,
        NotificationType.FULLSCREEN_PUSH,
        NotificationType.MODAL_PUSH,
      ].includes(notification?.notification_type)
    ) {
      this.createNotificationModal(notification)
      return
    }
    this.createNotificationToast(notification)
  }

  /**
   * when notificacion is push. have priority for update history
   * @param {INotification} notification
   */
  updateHistory(notification: INotification): void {
    if (
      [NotificationType.FULLSCREEN_PUSH, NotificationType.MODAL_PUSH].includes(
        notification.notification_type as NotificationType
      )
    ) {
      this._store.dispatch(withdrawalsClear())
      this._store.dispatch(recordsClearAll())
    }
  }
}
