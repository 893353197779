<ion-app>
  <ion-split-pane [when]="'(min-width: 768px)'" contentId="main-content">
    <ion-menu
      menuId="main"
      contentId="main-content"
      [side]="side"
      [hidden]="url.includes('/u/guide/tutorial/')"
      [swipeGesture]="false"
      [disabled]="!splitPaneService.isVisible"
    >
      <ion-header>
        <ion-toolbar color="primary" class="shadow-none px-3.5 mt-2 h-auto">
          <div class="flex items-center">
            <img src="assets/icon/ic_logo_white.svg" class="block md:hidden" />
            <img src="assets/icon/img_logo-small_white.svg" class="hidden md:block" tappable [routerLink]="['/']" />
            <img
              src="../assets/matraz.svg"
              alt="Dev"
              title="Dev"
              *ngIf="!environment.production"
              class="pl-2 mt-1 md:mt-2"
            />
          </div>
          <ion-menu-toggle slot="end" tappable>
            <ion-icon size="large" name="close-outline" class="p-0 m-0"></ion-icon>
          </ion-menu-toggle>
        </ion-toolbar>
        <div color="primary" class="shadow-none ml-[18px] mr-3">
          <div class="md:pt-[38px] min-h-[135px]">
            <app-user-info-card [color]="'primary'"></app-user-info-card>
          </div>
        </div>
        <hr class="bg-white mb-[30px] mt-0 md:my-[38px]" />
      </ion-header>
      <ion-content>
        <div class="flex flex-col h-full" *ngrxLet="userSessionStatus$ as userSessionStatus">
          <div class="px-0 flex-grow">
            <ion-list lines="none" class="text-md flex flex-col md:gap-[2px] p-0">
              <h3 class="text-white pl-3.5 pr-4 pb-1 md:pb-2 md:px-4 m-0 font-semibold">Menú</h3>
              <ng-container *ngIf="siteTreeMenu$ | ngrxPush as siteMenu">
                <ion-menu-toggle class="hidden md:block">
                  <ion-item
                    routerLink="u/home"
                    #menu="routerLinkActive"
                    routerLinkActive="selected"
                    routerDirection="root"
                    detail="false"
                    color="primary"
                  >
                    <monto-icon
                      [size]="'full'"
                      class="max-w-[22px]"
                      slot="start"
                      color="light"
                      [name]="menu.isActive ? 'home-filled' : 'home'"
                    ></monto-icon>
                    <ion-label> Inicio</ion-label>
                  </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle
                  *ngIf="
                    siteMenu.profile &&
                    ![UserStatus.PENDING_VERIFY_PHONE, UserStatus.PENDING_VALIDATION].includes($any(userSessionStatus))
                  "
                  class="block"
                >
                  <ion-item
                    routerLink="u/user-profile"
                    #menu="routerLinkActive"
                    routerLinkActive="selected"
                    routerDirection="root"
                    detail="false"
                    color="primary"
                  >
                    <ion-icon
                      slot="start"
                      [src]="
                        menu.isActive
                          ? 'assets/icon/ic_profile_white_fill.svg'
                          : 'assets/icon/ic_profile_white_outline.svg'
                      "
                    ></ion-icon>
                    <ion-label> Mi Perfil</ion-label>
                  </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle
                  *ngIf="
                    siteMenu.payment_service &&
                    ![UserStatus.PENDING_VERIFY_PHONE, UserStatus.PENDING_VALIDATION].includes($any(userSessionStatus))
                  "
                  class="block"
                >
                  <ion-item
                    routerLink="u/payment-services"
                    #menu="routerLinkActive"
                    routerLinkActive="selected"
                    routerDirection="root"
                    detail="false"
                    color="primary"
                  >
                    <monto-icon
                      [size]="'full'"
                      class="max-w-[18px]"
                      slot="start"
                      color="light"
                      [name]="menu.isActive ? 'payment-service-filled' : 'payment-service'"
                    ></monto-icon>
                    <ion-label> Pago de servicios</ion-label>
                  </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle
                  *ngIf="
                    siteMenu.topups &&
                    ![UserStatus.PENDING_VERIFY_PHONE, UserStatus.PENDING_VALIDATION].includes($any(userSessionStatus))
                  "
                  class="block"
                >
                  <ion-item
                    routerLink="u/top-ups"
                    #menu="routerLinkActive"
                    routerLinkActive="selected"
                    routerDirection="root"
                    detail="false"
                    color="primary"
                  >
                    <monto-icon
                      [size]="'full'"
                      class="max-w-[20px]"
                      slot="start"
                      color="light"
                      [name]="menu.isActive ? 'topups-filled' : 'topups'"
                    ></monto-icon>
                    <ion-label> Tiempo Aire</ion-label>
                  </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle
                  class="block"
                  *ngIf="
                    (config$ | ngrxPush | config: 'FLAG_BENEFITS') === true &&
                    siteMenu.benefits &&
                    ![UserStatus.PENDING_VERIFY_PHONE, UserStatus.PENDING_VALIDATION].includes($any(userSessionStatus))
                  "
                >
                  <ion-item
                    routerLink="u/benefits"
                    #menu="routerLinkActive"
                    routerLinkActive="selected"
                    routerDirection="root"
                    detail="false"
                    color="primary"
                  >
                    <monto-icon
                      [size]="'full'"
                      class="max-w-[21px]"
                      slot="start"
                      color="light"
                      [name]="menu.isActive ? 'diamond-filled' : 'diamond'"
                    ></monto-icon>
                    <ion-label> Beneficios</ion-label>
                  </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle
                  *ngIf="
                    siteMenu.gift_cards &&
                    ![UserStatus.PENDING_VERIFY_PHONE, UserStatus.PENDING_VALIDATION].includes($any(userSessionStatus))
                  "
                  class="block"
                >
                  <ion-item
                    routerLink="u/gift-card"
                    #menu="routerLinkActive"
                    routerLinkActive="selected"
                    routerDirection="root"
                    detail="false"
                    color="primary"
                  >
                    <monto-icon
                      [size]="'full'"
                      class="max-w-[19px]"
                      slot="start"
                      color="light"
                      [name]="menu.isActive ? 'gift-filled' : 'gift'"
                    ></monto-icon>
                    <ion-label> Tarjetas de regalo</ion-label>
                  </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle
                  *ngIf="
                    siteMenu.history &&
                    ![UserStatus.PENDING_VERIFY_PHONE, UserStatus.PENDING_VALIDATION].includes($any(userSessionStatus))
                  "
                  class="hidden md:block"
                >
                  <ion-item
                    routerLink="u/records"
                    #menu="routerLinkActive"
                    routerLinkActive="selected"
                    routerDirection="root"
                    detail="false"
                    color="primary"
                  >
                    <monto-icon
                      [size]="'full'"
                      class="max-w-[19px]"
                      slot="start"
                      [color]="'light'"
                      [name]="menu.isActive ? 'clock-filled' : 'clock'"
                    ></monto-icon>
                    <ion-label> Historial</ion-label>
                  </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle
                  *ngIf="
                    (siteMenu.redeem || (config$ | ngrxPush | config: 'FLAG_BENEFIT_REDEEM') === true) &&
                    (user$ | ngrxPush)?.hasCompany
                  "
                  class="block"
                >
                  <ion-item tappable="" detail="false" color="primary" (click)="onOpenModalRedeem()">
                    <monto-icon
                      [size]="'full'"
                      class="max-w-[26px]"
                      slot="start"
                      [color]="'light'"
                      [name]="'trophy'"
                    ></monto-icon>
                    <ion-label> Redimir premio</ion-label>
                  </ion-item>
                </ion-menu-toggle>
                <ng-container *ngIf="siteMenu.refer">
                  <ng-template [ngIf]="userSessionStatus === UserStatus.ACTIVE" [ngIfElse]="recommendOption">
                    <ion-menu-toggle class="block">
                      <ion-item
                        lines="none"
                        color="primary"
                        routerDirection="root"
                        [routerLink]="['/u/refer']"
                        routerLinkActive="selected"
                        #menu="routerLinkActive"
                        [queryParams]="{ goBack: 'true' }"
                      >
                        <monto-icon
                          [size]="'full'"
                          class="max-w-[22px]"
                          slot="start"
                          color="light"
                          [name]="menu.isActive ? 'thumbs-up-filled' : 'thumbs-up'"
                        >
                        </monto-icon>
                        <ion-label> Recomienda Monto</ion-label>
                      </ion-item>
                    </ion-menu-toggle>
                  </ng-template>

                  <ng-template #recommendOption>
                    <ion-menu-toggle class="block">
                      <ion-item
                        lines="none"
                        color="primary"
                        routerDirection="root"
                        [routerLink]="['/unauth/recommend-company-inside']"
                        routerLinkActive="selected"
                        #menu="routerLinkActive"
                        [queryParams]="{ goBack: 'true' }"
                      >
                        <monto-icon
                          [size]="'full'"
                          class="max-w-[22px]"
                          slot="start"
                          color="light"
                          [name]="menu.isActive ? 'thumbs-up-filled' : 'thumbs-up'"
                        ></monto-icon>
                        <ion-label> Recomienda Monto</ion-label>
                      </ion-item>
                    </ion-menu-toggle>
                  </ng-template>
                </ng-container>
                <ion-menu-toggle *ngIf="siteMenu.about" class="block">
                  <ion-item
                    lines="none"
                    color="primary"
                    tappable
                    [routerLink]="['/u/about']"
                    routerLinkActive="selected"
                    #menu="routerLinkActive"
                    [queryParams]="{ goBack: 'true' }"
                  >
                    <monto-icon
                      [size]="'full'"
                      class="max-w-[22px]"
                      slot="start"
                      color="light"
                      [name]="menu.isActive ? 'monto-filled' : 'monto'"
                    ></monto-icon>
                    <ion-label>Contáctanos</ion-label>
                  </ion-item>
                </ion-menu-toggle>
              </ng-container>
            </ion-list>
          </div>
          <ion-menu-toggle class="block mt-auto pb-5">
            <ion-item lines="none" color="primary" lines="none" tappable (click)="logout()" class="pt-2">
              <ion-icon class="text-xl mr-3 my-1.5 md:my-0" slot="start" name="log-out-outline"></ion-icon>
              <ion-label> Cerrar sesión</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </div>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
