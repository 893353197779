<ion-toolbar *ngIf="showToolbar" [color]="colorToolbar" [ngClass]="{ 'pt-0 mt-0': isModal }"></ion-toolbar>
<ng-container *ngIf="menuFixed">
  <ion-header>
    <ng-container *ngTemplateOutlet="toolbarTpl"></ng-container>
  </ion-header>
</ng-container>
<ion-content>
  <div class="fixed inset-0 h-full w-full" *ngIf="background">
    <div class="background top-1/3 w-full max-w-[395px] -left-1/2"></div>
    <div class="background top-3/5 w-full max-w-[268px] -right-1/2"></div>
  </div>
  <div class="content-main">
    <ng-container *ngIf="showToolbar">
      <ng-container *ngIf="!menuFixed">
        <ng-container *ngTemplateOutlet="toolbarTpl"></ng-container>
      </ng-container>
      <div
        [ngClass]="{
          'header-desktop': !isModal,
          'header-modal': isModal,
          'is-native': isNative
        }"
      >
        <div class="flex justify-between items-center">
          <div class="w-full max-w-web-area">
            <ng-container *ngTemplateOutlet="templateHeader"></ng-container>
          </div>
          <div
            class="flex justify-end section-close"
            [ngClass]="{
              'xl:pr-[100px]': !isModal
            }"
          >
            <ion-button (click)="onClose()" fill="clear" *ngIf="isClosable" [disabled]="disableClose">
              <ion-icon size="large" [color]="'dark'" slot="icon-only" name="close-outline"></ion-icon>
            </ion-button>
          </div>
        </div>
      </div>
    </ng-container>
    <div
      [ngClass]="{
        'content-body': !isModal,
        'content-modal': isModal,
        padding: padding,
        'is-native': isNative
      }"
      class="flex flex-col"
    >
      <ng-content></ng-content>
    </div>
  </div>
</ion-content>
<ng-content select="[footer]"></ng-content>
<ng-template #toolbarTpl>
  <app-toolbar
    [loadIndicatorNewNotification]="loadIndicatorNewNotification"
    [templateExtraButtons]="templateExtraButtons"
    [isModal]="isModal"
    [closable]="isClosable"
    [disableClose]="disableClose"
    #toolbar
    (close)="onClose()"
    [templateHeader]="templateHeader"
    [menu]="menu"
    [color]="colorToolbar"
  >
  </app-toolbar>
  <ng-container *ngIf="completeProfileBanner">
    <div
      (click)="onLocateCompany()"
      tappable=""
      *ngIf="!(user$ | ngrxPush)?.hasCompany"
      class="w-full cursor-pointer min-h-[39px] mt-3.5 md:mt-0 flex bg-primary py-1.5 gap-1 px-4 pr-[14px] items-center shadow-md group hover:shadow-xs"
    >
      <section>
        <ion-icon
          name="person-outline"
          [style]="{ '--ionicon-stroke-width': '48px' }"
          class="text-white pt-[4px] group-hover:opacity-80"
        >
        </ion-icon>
      </section>
      <section class="text-white flex-grow font-semibold group-hover:opacity-80 text-sm sm:text-base">
        Completa tu perfil y paga con tu salario
      </section>
      <section class="flex items-center">
        <ion-icon name="chevron-forward-outline" class="text-white group-hover:opacity-80"></ion-icon>
      </section>
    </div>
  </ng-container>
</ng-template>
