import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { IonicModule, ModalController } from '@ionic/angular'
import { CommonModule, Location } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { SharedPipesModule } from '@pipes/shared-pipes.module'
import { Browser } from '@capacitor/browser'
import { IStore } from '@components/pages/stores/stores'
import { StoresResource } from '@components/pages/stores/stores.resource'
import { Router } from '@angular/router'
import { GeolocationService } from '@services/geolocation.service'
import { ToolbarModule } from '@components/toolbar/toolbar.module'
import { finalize } from 'rxjs/operators'
import { DistancePipe } from '@pipes/distance.pipe'
import { BenefitCardModule } from '@modules/benefit/components/benefit-card/benefit-card.module'
import { ContentMainComponent } from '@components/content-main/content-main.component'

@Component({
  selector: 'page-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedPipesModule,
    ToolbarModule,
    DistancePipe,
    BenefitCardModule,
    ContentMainComponent,
  ],
  providers: [StoresResource, GeolocationService],
})
export class StoresComponent implements OnInit {
  /**
   * Show logo in page
   * @type {string}
   */
  @Input() showLogo: boolean = true

  /**
   * Show search in page
   * @type {boolean}
   */
  @Input() showName: boolean = true

  /**
   * Footer in page
   * @type {boolean}
   */
  @Input() footer!: string

  /**
   * Title   for list stores
   * @type {string}
   */
  @Input() title!: string

  /**
   * Define type store for list
   * @type {string}
   */
  @Input() provider!: string

  /**
   * Text for legen in page
   * @type {string}
   */
  @Input() legend!: string

  /**
   * Stores list
   * @type {IStore[] | null}
   */
  public stores: IStore[] | null = null

  /**
   * Loading stores
   * @type {boolean}
   */
  public isLoading: boolean = false

  /**
   * Offset
   */
  public offset: number = 0

  /**
   * Show more
   */
  public showMore: boolean = true

  /**
   * Limit store service
   */

  public limit: number = 25

  /**
   * Constructor
   * @param {ModalController} _modalcontroller
   * @param {StoresResource} _storesResource
   * @param {ChangeDetectorRef} _cdr
   * @param {Router} _route
   * @param {GeolocationService} geolocationService
   * @param {Location} _location
   */
  constructor(
    private _modalcontroller: ModalController,
    private _storesResource: StoresResource,
    public _cdr: ChangeDetectorRef,
    private _route: Router,
    public geolocationService: GeolocationService,
    private _location: Location
  ) {}

  /**
   * On init
   * @returns {Promise<void>}
   */
  async ngOnInit() {
    await this.geolocationService.getCurrentPosition()
    if (this._route.routerState) {
      this.provider = history.state['provider'] ?? this.provider
      this.legend = history.state['legend'] ?? this.legend
      this.title = history.state['title'] ?? this.title
      this.showLogo = history.state['showLogo'] ?? this.showLogo
      this.showName = history.state['showName'] ?? this.showName
      this.footer = history.state['footer'] ?? this.footer
    }

    this.isLoading = true
    this.loadStores()
  }

  /**
   * Load stores
   */
  loadStores() {
    this.isLoading = true
    this._cdr.detectChanges()
    this._storesResource
      .list({
        provider: this.provider,
        lat: this.geolocationService.currentPosition?.coords?.latitude || null,
        lng: this.geolocationService.currentPosition?.coords?.longitude || null,
        limit: this.limit,
        offset: this.offset,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false
          this._cdr.detectChanges()
        })
      )
      .subscribe({
        next: response => {
          this.showMore = response.count > this.offset + this.limit
          if (response?.data?.length) {
            this.stores = response.data
            this._cdr.detectChanges()
          }
        },
      })
  }

  /**
   * On load more
   */
  onLoadMore() {
    this.offset += this.limit
    this.loadStores()
  }

  /**
   * On dismiss
   */
  onDissmiss() {
    this._location.historyGo(-1)
  }

  /**
   * Open url
   * @param {string} url
   * @returns {Promise<void>}
   */
  async onOpenUrl(url: string) {
    await Browser.open({ url: url })
  }
}
