import { NotificationType } from '@modules/notification/enums/notification'

export enum ENotificationTemplate {
  DEFAULT = 'default',
  IMAGE_BACKGROUND = 'image-background',
  IMAGE_ONLY = 'image-only',
}

export interface INotification {
  created_at?: string
  date?: string
  event_data?: Record<any, any>
  event_type?: string
  id: string
  body?: string
  body_clean?: string
  delete_at?: string
  role?: string
  status?: string
  title?: string
  title_clean?: string
  view_at?: string | null
  viewed?: boolean
  detail?: boolean
  is_loading?: boolean
  buttons?: any[] | null
  image?: string
  notification_type?: NotificationType | null
  data?: any
  unread?: number
}

export interface INotificationState {
  entity: Record<string, any> | null
  loading: boolean
  entities: any[] | null
  indicatorNew: boolean
  notification: INotification | null
}
