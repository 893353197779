<div class="flex flex-col text-dark inner-content">
  <div class="flex justify-end p-3 pb-0">
    <a href="javascript:" (click)="onDissmiss(false)">
      <ion-icon name="close-outline" class="text-dark" size="large"></ion-icon>
    </a>
  </div>
  <div class="pt-[25px] pb-[42px] px-4 md:pt-[20px] grow -inset-1">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <h2 class="text-[22px] text-center leading-6 mb-0" [innerHTML]="'Acceso a contenido multimedia'"></h2>
        </ion-col>
        <ion-col size="12" class="pt-[29px]">
          <div class="text-base text-center leading-[18px]">
            Ingresa en tu dispositivo a la sección de <span class="font-semibold">Configuración</span>, elige
            Aplicaciones, selecciona <span class="font-semibold">Monto</span> y en permisos activa
            <span class="font-semibold">“Permitir acceso al contenido multimedia”</span> y listo.
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <ion-toolbar color="light" class="flex-none bg-transparent px-4 pb-1.5">
    <div class="flex flex-col justify-center">
      <ion-button expand="block" color="primary" (click)="onDissmiss(true)"> Permitir acceso </ion-button>
      <ion-button expand="block" color="light" (click)="onDissmiss(false)">Cancelar</ion-button>
    </div>
  </ion-toolbar>
</div>
