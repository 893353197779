<div
  class="flex flex-col text-dark gap-1 w-full inner-content h-full"
  [ngClass]="{ 'image-background-notification': template === ENotificationTemplate.IMAGE_BACKGROUND }"
>
  <div class="flex justify-end p-3 pb-0 z-10">
    <ion-button class="m-0 -mr-1" slot="end" (click)="onDismiss()" fill="clear">
      <ion-icon size="large" slot="icon-only" class="h-5 w-5" name="close-outline"> </ion-icon>
    </ion-button>
  </div>
  <div class="body-modal-notification flex grow flex-col gap-2">
    <div class="grow flex flex-col">
      <header *ngIf="notification?.title && template !== ENotificationTemplate.IMAGE_ONLY">
        <h2 class="text-lg text-center mb-0" [innerHTML]="notification?.title | sanitizeHtml: 'html'"></h2>
      </header>
      <section class="flex gap-3.5 content-body-notification">
        <section *ngIf="notification?.body">
          <div [innerHTML]="notification?.body | sanitizeHtml: 'html'" class="text-center"></div>
        </section>
        <section *ngIf="notification?.image" class="text-center m-0 image-container-notification image-modal"></section>
      </section>
    </div>
    <footer class="flex bg-transparent" *ngIf="notification?.buttons?.length">
      <ion-button
        expand="block"
        *ngFor="let button of notification?.buttons"
        (click)="onAction(button?.link)"
        [color]="button?.color ? button?.color : 'light'"
      >
        {{ button?.message }}
      </ion-button>
    </footer>
  </div>
</div>
