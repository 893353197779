import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { ConfigModule } from '@modules/config/config.module'
import { MontoCashEffects } from '@modules/monto-cash/effects/monto-cash.effect'
import { MontoWithdrawService } from '@modules/withdrawal/services/withdrawal.service'
import { EffectsModule } from '@ngrx/effects'
import { SharedModule } from 'src/app/shared/shared.module'
import { UserInfoCardComponent } from 'src/app/modules/user/components/user-info-card/user-info-card.component'
import { BtnCompleteProfileComponent } from '@modules/user/components/btn-complete-profile/btn-complete-profile.component'
import { PushPipe } from '@ngrx/component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    RouterModule,
    EffectsModule.forFeature([MontoCashEffects]),
    ConfigModule,
    PushPipe,
    BtnCompleteProfileComponent,
  ],
  exports: [UserInfoCardComponent],
  declarations: [UserInfoCardComponent],
  providers: [MontoWithdrawService],
})
export class UserInfoCardModule {}
