import { CepRequest } from '@models/cep-request'
import type { ICepRequestResponse } from '@declarations/cep-request-respose'
import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import { catchError, distinctUntilChanged, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators'
import type { Observable } from 'rxjs'
import { of } from 'rxjs'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import type { IWithdrawal, IWithdrawalState } from '@modules/withdrawal/interfaces/withdrawal'
import { getAllWithdrawal } from '@modules/withdrawal/selectors/withdrawal.selectors'
import { Store } from '@ngrx/store'
import { PayrollPeriodCycleViewModel } from '@modules/withdrawal/models/payroll-period-cycle.view-model'
import { IAvailableWithdrawQuantity } from '@declarations/available-withdraw-quantity'
import { UserModel } from '@modules/user/models/user.model'
import { employeeActiveSelector, selectUser, userBalanceSelector } from '@modules/user/selectors/user.selectors'
import { IUserState } from '@modules/user/interfaces/user'
import { selectSiteTreeHome } from '@modules/app/selectors/site-tree.selectors'
import { userPutBalance } from '@modules/user/actions/user.actions'

@Injectable()
export class MontoWithdrawService {
  withdrawals$: Observable<IWithdrawal[] | null>
  withdrawal$: Observable<IWithdrawalState>
  public currentPeriod: PayrollPeriodCycleViewModel | null = null
  user$: Observable<UserModel | null>

  constructor(
    private httpClient: HttpClient,
    private _store: Store<{ withdrawal: IWithdrawalState; user: IUserState }>
  ) {
    this.withdrawal$ = this._store.select('withdrawal')
    this.withdrawals$ = this._store.select(getAllWithdrawal)
    this.user$ = this._store.select(selectUser)
  }

  getAvailableWithdrawQuantity(): Observable<IAvailableWithdrawQuantity> {
    return this.user$.pipe(
      distinctUntilChanged(),
      filter(user => !!user),
      take(1),
      withLatestFrom(
        this._store.select(userBalanceSelector),
        this._store.select(selectSiteTreeHome),
        this._store.select(employeeActiveSelector)
      ),
      switchMap(([user, balance, home, employeeActive]) => {
        if (employeeActive && home?.withdrawal) {
          if (balance !== undefined) {
            return of({
              monto: {
                available_balance: balance,
              },
            } as IAvailableWithdrawQuantity)
          }
          return this.httpClient
            .get(`${environment.API}/monto_available_balance`)
            .pipe(map(response => response as IAvailableWithdrawQuantity))
        } else {
          return of({
            monto: {
              available_balance: null,
            },
          } as IAvailableWithdrawQuantity)
        }
      }),
      catchError(() => {
        return of({
          monto: {
            available_balance: null,
          },
        } as IAvailableWithdrawQuantity)
      }),
      map(response => {
        if (response.monto.available_balance !== null) {
          if (response.monto.available_balance < 0) {
            response.monto.available_balance = 0
          }
        }
        this._store.dispatch(userPutBalance({ balance: response.monto.available_balance }))
        return response
      })
    )
  }

  requestCEP(id: any): Observable<CepRequest> {
    const mapResponseToCEPRequest = (cepRequest: ICepRequestResponse): CepRequest => new CepRequest(cepRequest)

    return this.httpClient.get(`${environment.API}/montos/${id}/cep_request`).pipe(map(mapResponseToCEPRequest))
  }

  downloadCEP(id: any, format: any, nonce: any): Observable<ArrayBuffer> {
    let headers = new HttpHeaders()
    let RequestHttpParams = new HttpParams()
    headers = headers.set('X-Monto-CEP-nonce', nonce)
    RequestHttpParams = RequestHttpParams.set('format', format)

    const options: {
      headers?: HttpHeaders
      observe?: 'body'
      params?: HttpParams
      reportProgress?: boolean
      responseType: 'arraybuffer'
      withCredentials?: boolean
    } = {
      headers,
      params: RequestHttpParams,
      responseType: 'arraybuffer',
    }

    return this.httpClient.get(`${environment.API}/montos/${id}/ceps`, options).pipe(map((file: ArrayBuffer) => file))
  }
}
