import type { PipeTransform } from '@angular/core'
import { Pipe } from '@angular/core'
import type { IConfig } from '../interfaces/config'

@Pipe({
  name: 'config',
})
export class ConfigPipe implements PipeTransform {
  transform(config: IConfig | undefined | null, key: string): any {
    if (!config) {
      return null
    }
    if (key in config) {
      return config[key as keyof IConfig]
    }
    return null
  }
}
