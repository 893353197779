import type { Routes } from '@angular/router'
import { canActivateTutorial } from '@guard/tutorial-guard.guard'
import { canIntermittence } from '@guard/intermittence.guard'
import { EServicesAvailableStatus } from '@modules/user/models/services'

export const applicationROUTES: Routes = [
  {
    path: 'stores',
    loadComponent: (): Promise<any> => import('@components/pages/stores/stores.component').then(m => m.StoresComponent),
  },
  {
    path: 'help',
    loadChildren: (): Promise<any> => import('../../pages/application/help/help.module').then(m => m.HelpPageModule),
  },
  {
    path: 'monto-cash',
    canActivate: [canIntermittence],
    data: {
      service: EServicesAvailableStatus.MONTO_CASH,
    },
    loadChildren: (): Promise<any> =>
      import('../../modules/monto-cash/pages/monto-cash-routing.module').then(m => m.CashPageRoutingModule),
  },
  {
    path: 'about',
    loadChildren: (): Promise<any> => import('../../pages/application/about/about.module').then(m => m.AboutPageModule),
  },
  {
    path: 'welcome',
    loadChildren: (): Promise<any> =>
      import('../../pages/application/welcome/welcome.module').then(m => m.WelcomePageModule),
  },
  {
    path: 'recommend',
    loadChildren: (): Promise<any> => import('@modules/recommend/recommend.module').then(m => m.RecommendPageModule),
  },
  {
    path: 'recommend-email-content',
    loadChildren: (): Promise<any> =>
      import('@modules/recommend/email-content/email-content.module').then(m => m.EmailContentPageModule),
  },
  {
    path: 'verify-information',
    loadChildren: (): Promise<any> =>
      import('../../pages/application/verify-information/verify-information.module').then(
        m => m.VerifyInformationPageModule
      ),
  },
  {
    path: 'congratulations',
    loadChildren: (): Promise<any> =>
      import('../../pages/application/congratulations/congratulations-routing.module').then(
        m => m.CongratulationsPageRoutingModule
      ),
  },
  {
    path: 'send-code',
    loadChildren: (): Promise<any> =>
      import('../../pages/application/send-code/send-code.module').then(m => m.SendCodePageModule),
  },
  {
    path: 'verify-information-rfc',
    loadChildren: (): Promise<any> =>
      import('../../pages/application/verify-information-rfc/verify-information-rfc.module').then(
        m => m.VerifyInformationRfcPageModule
      ),
  },
  {
    path: 'update-password',
    loadChildren: (): Promise<any> =>
      import('../../pages/application/update-password/update-password.module').then(m => m.UpdatePasswordPageModule),
  },
  {
    path: 'validate-code',
    loadChildren: (): Promise<any> =>
      import('../../pages/application/validate-code/validate-code.module').then(m => m.ValidateCodePageModule),
  },
  {
    path: 'locate-your-company',
    loadChildren: (): Promise<any> =>
      import('../../pages/application/locate-your-company/locate-your-company-routing.module').then(
        m => m.LocateYourCompanyPageRoutingModule
      ),
  },
  {
    path: 'integration',
    loadChildren: (): Promise<any> =>
      import('../../modules/integration/pages/integration-routing.module').then(m => m.IntegrationPageRoutingModule),
  },
  {
    path: 'payment-summary',
    loadComponent: (): Promise<any> =>
      import('src/app/pages/application/payment-service-summary-page/payment-service-summary.page').then(
        m => m.PaymentServiceSummaryPage
      ),
  },
  {
    path: 'successful-purchase',
    loadChildren: () =>
      import('../../pages/application/successful-purchase/successful-purchase.module').then(
        m => m.SuccessfulPurchasePageModule
      ),
  },
  {
    path: 'credit-cash',
    canActivate: [canIntermittence, canActivateTutorial],
    data: {
      tutorial: 'credit_cash',
      service: EServicesAvailableStatus.CREDITS,
    },
    loadChildren: () =>
      import('../../modules/credit-cash/pages/credit-cash-routing.module').then(m => m.CreditCashPageRoutingModule),
  },
  {
    path: '',
    loadChildren: (): Promise<any> => import('../../pages/application/tabs/tabs.module').then(m => m.TabsModule),
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('@modules/checkout/pages/checkout-routing.module').then(m => m.CheckoutPageRoutingModule),
  },
]
