import { Injectable } from '@angular/core'
import { Geolocation } from '@capacitor/geolocation'
import { AppLauncher } from '@capacitor/app-launcher'
import { Position } from '@capacitor/geolocation/dist/esm/definitions'
import { Capacitor } from '@capacitor/core'

/**
 * Geolocation service
 * Get current position and open map to position
 */
@Injectable()
export class GeolocationService {
  /**
   * Current position
   * @type {Partial<Position> | null}
   * @private
   */
  #currentPosition: Partial<Position> | null = null

  /**
   * Get current position
   * @returns {Partial<Position> | null}
   */
  get currentPosition(): Partial<Position> | null {
    return this.#currentPosition
  }

  /**
   * Set current position
   * @param {Partial<Position> | null} value
   */
  set currentPosition(value: Partial<Position> | null) {
    this.#currentPosition = value
  }

  /**
   * Get current position
   * @returns {Promise<Partial<Position>>}
   */
  async getCurrentPosition(): Promise<Partial<Position> | null> {
    try {
      if (Capacitor.getPlatform() === 'android') {
        this.currentPosition = null
      } else {
        this.currentPosition = await Geolocation.getCurrentPosition()
      }
    } catch (e) {
      this.currentPosition = null
    }

    return this.currentPosition
  }

  /**
   * Open map to position
   * @param {{latitude?: number, longitude?: number, address?: string}} position
   * @returns {Promise<void>}
   */
  async openMapTo(position: { latitude?: number; longitude?: number; address?: string }) {
    //get current position
    await this.getCurrentPosition()
    try {
      //try open google maps
      this.openGoogleMapsTo(position).then(value => {
        if (!value) {
          //if google maps not installed open apple maps
          this.opensMapsTo(position)
        }
      })
    } catch (e) {
      //if catch error open google maps, try open apple maps
      this.opensMapsTo(position)
    }
  }

  /**
   * Open app maps to position
   * @param {{latitude?: number, longitude?: number, address?: string}} position
   * @returns {Promise<boolean>}
   * @private
   */
  private async opensMapsTo(position: { latitude?: number; longitude?: number; address?: string }) {
    const queryParams = this.getQueryParams(position)
    const { completed } = await AppLauncher.openUrl({
      url: `https://maps.apple.com${queryParams}`,
    })
    return completed
  }

  /**
   * Open app google maps to position
   * @param {{latitude?: number, longitude?: number, address?: string}} position
   * @returns {Promise<boolean>}
   * @private
   */
  private async openGoogleMapsTo(position: { latitude?: number; longitude?: number; address?: string }) {
    const queryParams = this.getQueryParams(position)

    const { completed } = await AppLauncher.openUrl({
      url: `https://maps.google.com/maps${queryParams}`,
    })
    return completed
  }

  /**
   * Set saddr and daddr
   * @param {{latitude?: number, longitude?: number, address?: string}} position
   * @returns {string}
   */
  getQueryParams(position: { latitude?: number; longitude?: number; address?: string }) {
    let saddr = ''

    if (this.currentPosition?.coords) {
      saddr = `saddr=${this.currentPosition?.coords?.latitude},${this.currentPosition?.coords?.longitude}&`
    }

    return `?${saddr}daddr=${
      !position.latitude ? encodeURIComponent(position.address || '') : `${position.latitude},${position.longitude}`
    }`
  }
}
