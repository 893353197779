import { Component } from '@angular/core'
import { IonicModule, ModalController } from '@ionic/angular'
import { CommonModule } from '@angular/common'
import { SharedPipesModule } from '@pipes/shared-pipes.module'

@Component({
  selector: 'app-permission-modal',
  templateUrl: 'permission-modal.component.html',
  styleUrls: ['permission-modal.component.scss'],
  imports: [CommonModule, IonicModule, SharedPipesModule],
  standalone: true,
})
export class PermissionModalComponent {
  constructor(public _modalController: ModalController) {}

  onDissmiss(value: boolean) {
    this._modalController.dismiss({
      value,
    })
  }
}
