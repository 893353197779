import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, AfterViewInit } from '@angular/core'
import { Router } from '@angular/router'
import { Browser } from '@capacitor/browser'
import { ModalController } from '@ionic/angular'
import { montoCashLoadBalance } from '@modules/monto-cash/actions/monto-cash.actions'
import { ENotificationTemplate, INotification } from '@modules/notification/interfaces/notification'
import { IWithdrawalState } from '@modules/withdrawal/interfaces/withdrawal'
import { Store } from '@ngrx/store'
import { NotificationType } from '@modules/notification/enums/notification'

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationModalComponent implements OnInit, AfterViewInit {
  @Input() notification!: INotification
  NotificationType = NotificationType
  image: string = ''
  template = ENotificationTemplate.DEFAULT
  ENotificationTemplate = ENotificationTemplate

  defaultVarsImageBackground = {
    '--notification-text-color-button': '#fff',
    '--notification-color-text': '#fff',
    '--notification-buttons-direction': 'column',
    '--notification-buttons-gap': '150px',
  }

  defaultVarsImageBackgroundModal = {
    '--notification-ratio': '263/357',
  }

  constructor(
    protected _modalController: ModalController,
    protected _router: Router,
    private _store: Store<{ withdrawal: IWithdrawalState }>,
    protected el: ElementRef,
    protected _cdr: ChangeDetectorRef
  ) {}

  callActions(action: string): void {
    switch (action) {
      //update monto cash when receive notification
      case 'CALL_UPDATE_MONTO_CASH':
        this._store.dispatch(montoCashLoadBalance())
        break
      default:
        break
    }
  }

  async onAction(link: string): Promise<void> {
    //close modal
    this.onDismiss()
    if (link && link.startsWith('CALL_UPDATE')) {
      this.callActions(link)
      return
    }

    if (!link) {
      return
    }
    //return if dismiss
    if (link === 'dismiss') {
      return
    }

    //check if url external open browser
    if (link.startsWith('https://') || link.startsWith('http://')) {
      await Browser.open({ url: link })
      return
    }

    //if navigate in app navigate
    setTimeout(() => {
      this._router.navigateByUrl(link)
    }, 700)
  }

  onDismiss(): void {
    this._modalController.dismiss({
      dismissed: true,
    })
  }

  ngOnInit(): void {}

  /**
   * check if notification has image
   */
  chekImage() {
    if (this.notification && this.notification.image) {
      try {
        new URL(this.notification.image)
        this.image = `<img src="${this.notification.image}" class="w-full mx-auto md:px-0 " />`
      } catch (_) {
        this.image = this.notification.image
      }

      setTimeout(() => {
        if (this.el.nativeElement.querySelector('.image-container-notification')) {
          this.el.nativeElement.querySelector('.image-container-notification').innerHTML = `<div class="${
            this.template == ENotificationTemplate.IMAGE_BACKGROUND ? 'image-background-modal-notification' : ''
          } w-full">${this.image}</div>`
        }
      })
      this._cdr.detectChanges()
    }
  }

  ngAfterViewInit() {
    this.checkTemplate()
    this.chekImage()
  }

  /**
   * Check if notification has template
   */
  checkTemplate() {
    const computedStyle = window.getComputedStyle(document.documentElement)
    //get template
    const template = computedStyle.getPropertyValue(`--notification-template`)
    if (template) {
      this.template = template as ENotificationTemplate
    }

    document.documentElement.removeAttribute('style')

    if (this.template === ENotificationTemplate.IMAGE_BACKGROUND) {
      //if template is image background set default vars
      this.setDefaultVars(this.defaultVarsImageBackground)
      if (
        [NotificationType.MODAL, NotificationType.MODAL_PUSH].includes(
          this.notification.notification_type as NotificationType
        )
      ) {
        //if notification type is modal set default vars
        this.setDefaultVars(this.defaultVarsImageBackgroundModal)
      }
    }
    this._cdr.detectChanges()
  }

  /**
   * Set default vars
   * @param {Record<string, string>} defaultVars
   */
  setDefaultVars(defaultVars: Record<string, string>) {
    const computedStyle = window.getComputedStyle(document.documentElement)
    Object.keys(defaultVars).forEach(key => {
      if (!computedStyle.getPropertyValue(key)) {
        document.documentElement.style.setProperty(key, defaultVars[key])
      }
    })
  }
}
