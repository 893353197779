import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import { Capacitor } from '@capacitor/core'

declare let ClarityPlugin: any

@Injectable({ providedIn: 'root' })
export class ClarityService {
  constructor() {}

  async init(): Promise<void> {
    if (!this.checkAvailable()) {
      return
    }
    ClarityPlugin.initialize(
      environment.clarityKey,
      () => {},
      () => {},
      true
    )
  }

  checkAvailable(): boolean {
    if (!Capacitor.isNativePlatform()) {
      return false
    }

    if (Capacitor.getPlatform() !== 'android') {
      return false
    }

    return environment.production
  }

  setCustomUserId(id: string) {
    if (!this.checkAvailable()) {
      return
    }
    ClarityPlugin?.setCustomUserId(
      id,
      () => {},
      () => {}
    )
  }
}
