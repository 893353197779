import { IGiftCard } from '@modules/benefit/interfaces/gift-card'
import { EFileStatus } from '@modules/credit-cash/enums/credit-cash'
import { ECep, EOperationBillPaymentType } from '@modules/withdrawal/enums/withdrawal'
import { EOperationType } from '@modules/withdrawal/enums/withdrawal'

export enum ERecordOption {
  GENERAL = 0,
  CREDITS = 1,
  OTHER = 2,
}

export enum ERecordPaymentStatus {
  CANCELED = 0,
  ACCEPTED = 1,
  DECLINED = 2,
  PENDING = 3,
  CREATED = 4,
}

export enum EPaymentOperationType {
  CREDIT = 'credit',
  CARD = 'card',
  GIFTCARD = 'gift_card',
}

export enum EPaymentTermType {
  WEEKLY = 'semanal',
  DECENAL = 'decenal',
  BIWEEKLY = 'catorcenal',
  PERCYCLE = 'quincenal',
  MONTHLY = 'mensual',
  YEARLY = 'anual',
}

export enum EPaymentStatus {
  CREATED = 'created',
  PENDING = 'pending',
  PAIDOUT = 'paid_out',
  CANCELED = 'canceled',
  REFUSED = 'refused',
}

export enum ETransactionStatus {
  APPLIED = 'applied',
  PENDING = 'pending',
}

export interface IRecordSearchParams {
  startDate?: null | string
  endDate?: null | string
  amount?: string | number
}

export interface IRecordSearchParamsFormatted {
  between_dates?: string | null
  amount?: string | number
}

export interface IRecordPagination {
  limit: number
  offset: number
  count: number
}

export interface IRecordGiftCard {
  id: string
  name: string
  legend: string
  logo: string
  card: string
  pin: string | null
  amount: number
  folio: string | null
  status: string
  card_img: string | null
  gift_card_expiration_Date: string | null
}

export interface ICard extends IGiftCard {
  card_expiration_date?: string | null
  card_start_date?: string | null
}

export interface IServiceTransaction {
  amount: number
  total_amount: number
  date: string
  monto_transfer_id: string
  num_payment: number
  term: number
  payroll_period_cycle_id: string
  status: ETransactionStatus
  type: EPaymentOperationType
}

export interface ILoanApplication {
  id: string | null
  credit_file_id: string | null
  document_count: number
  credit_file_status: EFileStatus | null
  status: EPaymentStatus
  response: string
  reason: string
  opening_cost: number
  deposit: number
}

export interface IEmployeeAccount {
  bank: string
  account: string
}

export interface ITransferInfo {
  date_transfered: string
  recipient_account: string
  recipient_bank: string
  cep_status: ECep | null
  cep_error: number | null
  monto_transfer_id: string
  payment_processor_id: string
  created_at: string
}

export interface IRecordPaymentPlan {
  id: string
  status: EPaymentStatus
  requested_amount: number
  pending_debt: number
  total_amount: number
  paid_amount: number
  service_transactions: IServiceTransaction[]
  term: number
  term_type: string
  actual_payment_position: number
  employee_account?: IEmployeeAccount | null
  credit?: IRecordCredit | null
  gift_card?: IRecordGiftCard | null
  card?: ICard | null
  loan_application: null | ILoanApplication
}

export interface IRecordCredit {
  amount: number
  commission: number
  created_at: string
  id: string
  status: EPaymentStatus
  tax: number
  total: number
  total_commission: number
  transfer_info: ITransferInfo
  file: {
    url: string | null
  } | null
}

export interface IRecordReceipt {
  id: string
  name: string | null
  path: string | null
  status: number
  employee_id: string
  payroll_period_cycle_id: string
  created_at: string
  updated_at: string
  pdf: boolean
  xml: boolean
  png: boolean
  simple: boolean
  remote_id: string
}

export interface IRecordPayrollPeriodCycle {
  active: boolean
  admin_confirmed: boolean
  created_at: string
  cutoff_date: string
  end_date: string
  id: string
  payment_date: string
  payroll_period_id: string
  reconciliation_date: string
  start_date: string
  updated_at: string
}

export interface IRecordSalary {
  amount: number
  commission: number
  id: string
  tax: number
  payroll_period_cycle?: IRecordPayrollPeriodCycle
}

export interface IRecordCardPayment {
  id: string
  payment_processor_id: string
  card_id: string
}

export interface IRecordMonto extends IRecordPaymentPlan {
  id: string
  amount: number
  code: string
  operation: EOperationType | EPaymentOperationType
  bp_category: EOperationBillPaymentType
  logo: string
  commission: number
  created_at: string
  payment_status: ERecordPaymentStatus
  cep_error: any // 0 error,1 success,null: not process :
  cep_status: ECep | null
  account: string
  bank: string
  collaborator: string
  comments: string
  company_name: string
  payment_processor_id: string
  tax: number
  time: string
  gift_card_code: string
  gift_cards: IGiftCard[]
  cards: ICard[]
  tracking_key: string
  monto_variable_salary: IRecordSalary | null
  monto_base_salary: IRecordSalary | null
  payroll_period_cycle: any
  expiration_date: string | null
  payment_plan?: IRecordPaymentPlan
  service_transaction: null | IServiceTransaction
  card_payment: IRecordCardPayment | null
}

export interface IRecordSummary {
  total_discount: number
  total_withdrawn: number
  count: number
}

export interface IRecordCycle extends IRecordMonto {
  id: string
  montos: IRecordMonto[]
  end_date: string
  start_date: string
  payment_date: string
  cutoff_date: string
  monto_count: number
  total_withdrawn: number
  total_discount: number
  monto_cash: number
  payroll_receipt: IRecordReceipt | null
  base: IRecordSummary | null
  variable: IRecordSummary | null
}

export interface IRecordResponse {
  cycles: IRecordCycle[]
  count: number
  limit: number
  offset: number
}

const ERecordOpt = ERecordOption

export interface IRecordState {
  searchParams: Record<string, any> | null
  pagination: {
    0: IRecordPagination
    1: IRecordPagination
    2: IRecordPagination
  }
  loading: boolean
  entities: {
    0: IRecordCycle[] | null
    1: IRecordCycle[] | null
    2: IRecordCycle[] | null
  }
  type: ERecordOption | number
  needsRefresh: boolean
  jumpCycle: IRecordCycle | null
}
