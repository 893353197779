<ion-content
  class="relative"
  [ngClass]="{ 'image-background-notification': template === ENotificationTemplate.IMAGE_BACKGROUND }"
  scroll-y="false"
  fullscreen="true"
>
  <div class="flex flex-col w-full h-full">
    <div class="flex justify-end p-3 pb-0">
      <ion-button class="m-0 -mr-1" slot="end" (click)="onDismiss()" fill="clear">
        <ion-icon size="large" slot="icon-only" class="h-5 w-5" name="close-outline"> </ion-icon>
      </ion-button>
    </div>
    <div class="grow relative">
      <div class="flex flex-col max-h-auto absolute inset-0">
        <div class="grow body-modal-notification flex flex-col relative">
          <div class="flex flex-col gap-2">
            <header>
              <h2
                class="mb-0 text-lg"
                *ngIf="notification?.title && template !== ENotificationTemplate.IMAGE_ONLY"
                [innerHTML]="notification?.title | sanitizeHtml: 'html'"
              ></h2>
            </header>
            <section class="flex gap-3.5 content-body-notification">
              <div
                *ngIf="notification?.body"
                [innerHTML]="notification?.body | sanitizeHtml: 'html'"
                class="lg:w-web-area"
              ></div>
            </section>
          </div>
          <div
            *ngIf="
              ![NotificationType.FULLSCREEN_PUSH, NotificationType.MODAL_PUSH].includes(
                $any(notification?.notification_type)
              )
            "
            class="grow xl:mt-0 flex items-center image-container-notification w-full"
          ></div>
          <div
            *ngIf="
              [NotificationType.FULLSCREEN_PUSH, NotificationType.MODAL_PUSH].includes(
                $any(notification?.notification_type)
              )
            "
            class="max-w-[1248px] absolute inset-0 xl:mt-0 flex items-center"
          >
            <img [src]="notification?.image" class="max-w-[378.44px] mx-auto px-5 md:px-0" />
          </div>
          <footer
            *ngIf="notification?.buttons?.length"
            class="flex mt-auto bg-transparent px-2 py-[20px] lg:pt-0 max-w-[1248px]"
          >
            <ion-button
              *ngFor="let button of notification?.buttons"
              (click)="onAction(button?.link)"
              [color]="button?.color ? button?.color : 'light'"
            >
              {{ button?.message }}
            </ion-button>
          </footer>
        </div>
      </div>
    </div>
  </div>
</ion-content>
