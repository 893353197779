import { ChangeDetectionStrategy, Component } from '@angular/core'
import { NotificationModalComponent } from '../notification-modal/notification-modal.component'

@Component({
  selector: 'app-notification-modal-fullscreen',
  templateUrl: './notification-modal-fullscreen.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['../notification-modal/notification-modal.component.scss'],
})
export class NotificationModalFullscreenComponent extends NotificationModalComponent {}
