import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import type { ISelectedPicture } from '@declarations/selected-picture'
import { IConfig } from '@modules/config/interfaces/config'
import { IMontoCashState } from '@modules/monto-cash/interfaces/monto-cash'
import { montoCashBalanceSelect, montoCashIsLoadingSelect } from '@modules/monto-cash/selectors/monto-cash.selectors'
import { IUserState } from '@modules/user/interfaces/user'
import { ProfileModel } from '@modules/user/models/profile.model'
import { selectUser } from '@modules/user/selectors/user.selectors'
import { Store } from '@ngrx/store'
import { ImagePickerService } from '@services/image-picker.service'
import type { Observable } from 'rxjs'
import { UserModel } from '@modules/user/models/user.model'
import { Capacitor } from '@capacitor/core'
import { PermissionsDocsService } from '@services/permissions-docs.service'
import { MenuController } from '@ionic/angular'

@Component({
  selector: 'app-user-info-card',
  templateUrl: './user-info-card.component.html',
  styleUrls: ['./user-info-card.component.scss'],
})
export class UserInfoCardComponent implements OnInit {
  @ViewChild('fileInput')
  fileInput!: ElementRef

  @Input() color = 'light'
  @Input() viewState: any
  @Input() picture: any

  @Output() setFile: EventEmitter<any> = new EventEmitter<any>()

  profile: ProfileModel | null = null
  url!: string

  montoCashbalance$: Observable<any>
  montoCashIsLoading$: Observable<any>
  public config$
  user$: Observable<UserModel | null>

  constructor(
    private imagePickerService: ImagePickerService,
    private _store: Store<{ config: IConfig; user: IUserState; montoCash: IMontoCashState }>,
    private router: Router,
    protected _permissionsDocsService: PermissionsDocsService,
    protected _menuController: MenuController
  ) {
    this.user$ = this._store.select(selectUser)
    this.config$ = this._store.select('config')
    this.montoCashbalance$ = this._store.select(montoCashBalanceSelect)
    this.montoCashIsLoading$ = this._store.select(montoCashIsLoadingSelect)
  }

  ngOnInit(): void {
    this.url = this.router.url
    this.user$.subscribe(user => {
      if (user) {
        this.profile = user.profile
      }
    })
  }
}
