import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ToolbarModule } from '@components/toolbar/toolbar.module'
import { IonicModule } from '@ionic/angular'
import { NotificationModalModule } from '../notification-modal/notification-modal.module'
import { NotificationModalFullscreenComponent } from './notification-modal-fullscreen.component'
import { SharedPipesModule } from '@pipes/shared-pipes.module'

@NgModule({
  imports: [IonicModule, CommonModule, NotificationModalModule, ToolbarModule, SharedPipesModule],
  exports: [NotificationModalFullscreenComponent],
  declarations: [NotificationModalFullscreenComponent],
})
export class NotificationModalFullscreenModule {}
