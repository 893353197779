import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { NotificationModalComponent } from './notification-modal.component'
import { SanitizeHtmlPipe } from '@pipes/sanitize-html.pipe'

@NgModule({
  imports: [IonicModule, CommonModule, SanitizeHtmlPipe],
  exports: [NotificationModalComponent],
  declarations: [NotificationModalComponent],
  providers: [SanitizeHtmlPipe],
})
export class NotificationModalModule {}
